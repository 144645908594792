import React, { useState, useEffect } from "react";
import '../wwwroot/css/Header.css';

export function Header(props) {
    return (
        <>
        <div className="header">
            <h1>Josh Hoffman</h1>
            <p>Software Developer</p>
        </div>
        </>
    );
}