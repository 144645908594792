import logo from './logo.svg';
import './App.css';
import { Header } from './components/Header';
import { Button } from './components/Button';
import { Card } from './components/Card';
import { Icon } from './components/Icon';

function App() {
  return (
    <div className="App">
      <Header />
      <Button label={"Guess Baby K's Name"} link="https://www.hoffmanjoshua.net/baby-k/index.html" btnClass={"hover-underline"}/>
      <Button label={"567dle"} link="https://www.hoffmanjoshua.net/567dle/index.html" btnClass={"hover-underline"}/>
      <Button label={"LinkedIn"} link="https://www.linkedin.com/in/joshua-hoffman-666499178" btnClass={"hover-underline"}/>
      <Button label={<Icon type={"Github"}/>} link="https://www.github.com/jhoffman5" btnClass={"hover-underline"} />
    </div>
  );
}

export default App;
