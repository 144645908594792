import React from 'react';

var IconSet = {
    Github: <svg className='icon' viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0.396c-8.839 0-16 7.167-16 16 0 7.073 4.584 13.068 10.937 15.183 0.803 0.151 1.093-0.344 1.093-0.772 0-0.38-0.009-1.385-0.015-2.719-4.453 0.964-5.391-2.151-5.391-2.151-0.729-1.844-1.781-2.339-1.781-2.339-1.448-0.989 0.115-0.968 0.115-0.968 1.604 0.109 2.448 1.645 2.448 1.645 1.427 2.448 3.744 1.74 4.661 1.328 0.14-1.031 0.557-1.74 1.011-2.135-3.552-0.401-7.287-1.776-7.287-7.907 0-1.751 0.62-3.177 1.645-4.297-0.177-0.401-0.719-2.031 0.141-4.235 0 0 1.339-0.427 4.4 1.641 1.281-0.355 2.641-0.532 4-0.541 1.36 0.009 2.719 0.187 4 0.541 3.043-2.068 4.381-1.641 4.381-1.641 0.859 2.204 0.317 3.833 0.161 4.235 1.015 1.12 1.635 2.547 1.635 4.297 0 6.145-3.74 7.5-7.296 7.891 0.556 0.479 1.077 1.464 1.077 2.959 0 2.14-0.020 3.864-0.020 4.385 0 0.416 0.28 0.916 1.104 0.755 6.4-2.093 10.979-8.093 10.979-15.156 0-8.833-7.161-16-16-16z"/>
</svg>,
    Twitter: <svg className='icon' viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" >
                <path d="m31.23161,6.45169c-0.50098,0.2222 -1.01605,0.40973 -1.54233,0.56198c0.62309,-0.70466 1.09814,-1.5338 1.38813,-2.44111c0.06501,-0.20337 -0.00237,-0.42599 -0.16953,-0.559c-0.16696,-0.13311 -0.39893,-0.14906 -0.58276,-0.04012c-1.11769,0.6629 -2.32353,1.13929 -3.58791,1.41786c-1.27364,-1.24452 -3.0035,-1.95279 -4.7918,-1.95279c-3.77483,0 -6.84592,3.07099 -6.84592,6.84571c0,0.2973 0.01883,0.59294 0.05606,0.88448c-4.6842,-0.41128 -9.03901,-2.71362 -12.02945,-6.38187c-0.10657,-0.13075 -0.27075,-0.20121 -0.43884,-0.18774c-0.16819,0.01317 -0.31931,0.10801 -0.40428,0.25378c-0.60652,1.04074 -0.92717,2.23105 -0.92717,3.44214c0,1.64953 0.58893,3.21459 1.62926,4.43752c-0.31633,-0.10956 -0.62329,-0.24648 -0.91627,-0.40912c-0.15729,-0.08754 -0.34924,-0.08621 -0.5055,0.00339c-0.15636,0.0896 -0.2544,0.2544 -0.25851,0.43452c-0.00072,0.03035 -0.00072,0.06069 -0.00072,0.09145c0,2.4622 1.32518,4.67895 3.35121,5.88716c-0.17406,-0.01739 -0.34801,-0.04259 -0.52083,-0.07561c-0.17817,-0.03405 -0.36138,0.02839 -0.48154,0.16428c-0.12036,0.13579 -0.16017,0.32507 -0.10472,0.49789c0.74992,2.34133 2.6807,4.06348 5.01482,4.58853c-1.93592,1.21253 -4.14979,1.84765 -6.47383,1.84765c-0.48493,0 -0.97264,-0.0285 -1.44996,-0.08497c-0.23712,-0.02819 -0.46384,0.11182 -0.5446,0.33731c-0.08075,0.22559 0.00483,0.47711 0.20656,0.60642c2.9856,1.91431 6.43783,2.92615 9.98325,2.92615c6.96987,0 11.33003,-3.28671 13.76034,-6.04394c3.03056,-3.43803 4.76866,-7.9887 4.76866,-12.48506c0,-0.18784 -0.00288,-0.37753 -0.00864,-0.56661c1.19566,-0.90084 2.22508,-1.99105 3.06276,-3.24412c0.12725,-0.19031 0.11347,-0.44183 -0.03395,-0.61702c-0.14721,-0.17529 -0.39255,-0.23197 -0.602,-0.13918l0.00001,0.00004z"/>
            </svg>
}

export const Icon = ({type}) => {
    return (
    <>
        {IconSet[type] ? IconSet[type] : type}
    </>
    );
}

/* <svg className='icon' width="320px" height="320px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 0.396c-8.839 0-16 7.167-16 16 0 7.073 4.584 13.068 10.937 15.183 0.803 0.151 1.093-0.344 1.093-0.772 0-0.38-0.009-1.385-0.015-2.719-4.453 0.964-5.391-2.151-5.391-2.151-0.729-1.844-1.781-2.339-1.781-2.339-1.448-0.989 0.115-0.968 0.115-0.968 1.604 0.109 2.448 1.645 2.448 1.645 1.427 2.448 3.744 1.74 4.661 1.328 0.14-1.031 0.557-1.74 1.011-2.135-3.552-0.401-7.287-1.776-7.287-7.907 0-1.751 0.62-3.177 1.645-4.297-0.177-0.401-0.719-2.031 0.141-4.235 0 0 1.339-0.427 4.4 1.641 1.281-0.355 2.641-0.532 4-0.541 1.36 0.009 2.719 0.187 4 0.541 3.043-2.068 4.381-1.641 4.381-1.641 0.859 2.204 0.317 3.833 0.161 4.235 1.015 1.12 1.635 2.547 1.635 4.297 0 6.145-3.74 7.5-7.296 7.891 0.556 0.479 1.077 1.464 1.077 2.959 0 2.14-0.020 3.864-0.020 4.385 0 0.416 0.28 0.916 1.104 0.755 6.4-2.093 10.979-8.093 10.979-15.156 0-8.833-7.161-16-16-16z"/>
            </svg>

            */