import React from 'react';
import '../wwwroot/css/Button.css';

export const Button = ({label, onClick, link, btnClass}) => {
    return (
        <>
        <a className={`btn cta ${btnClass ? btnClass : ''}`} onClick={onClick} href={link ? link : '#'}>
            {label}
        </a>
        </>
    )
}
